export default {
  account: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/layouts/account.vue").then(m => m.default || m),
  "admin-with-sidebar-menu": () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/layouts/admin-with-sidebar-menu.vue").then(m => m.default || m),
  "default-without-header": () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/layouts/default-without-header.vue").then(m => m.default || m),
  default: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/layouts/default.vue").then(m => m.default || m),
  empty: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/layouts/empty.vue").then(m => m.default || m),
  "form-mission": () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/layouts/form-mission.vue").then(m => m.default || m),
  "messages-params": () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/layouts/messages-params.vue").then(m => m.default || m),
  messages: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/layouts/messages.vue").then(m => m.default || m),
  quiz: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/layouts/quiz.vue").then(m => m.default || m),
  "register-steps": () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/layouts/register-steps.vue").then(m => m.default || m),
  "statistics-admin": () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/layouts/statistics-admin.vue").then(m => m.default || m),
  "statistics-public": () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/layouts/statistics-public.vue").then(m => m.default || m),
  support: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/layouts/support.vue").then(m => m.default || m),
  temoignage: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/layouts/temoignage.vue").then(m => m.default || m)
}