import { default as accessibilite7yy611giyEMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/accessibilite.vue?macro=true";
import { default as _91slug_93JfSx0uKueHMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/activites/[slug].vue?macro=true";
import { default as editFJkWZVpObkMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/activites/[id]/edit.vue?macro=true";
import { default as index0k2wjbPWqBMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/activites/[id]/index.vue?macro=true";
import { default as addembwm64q5UMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/activites/add.vue?macro=true";
import { default as indexAc1T6lfOJYMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/activites/index.vue?macro=true";
import { default as edit4Hv4HuGJTiMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/domaines/[id]/edit.vue?macro=true";
import { default as add95M3VeXyTzMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/domaines/add.vue?macro=true";
import { default as indexV3VElyz8ebMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/domaines/index.vue?macro=true";
import { default as editZBSVfTkqrjMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/modeles-mission/[id]/edit.vue?macro=true";
import { default as add4jM1ifz3cYMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/modeles-mission/add.vue?macro=true";
import { default as indexQpnPnIAWGEMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/modeles-mission/index.vue?macro=true";
import { default as editFgpejLGOC1Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/reseaux/[id]/edit.vue?macro=true";
import { default as indexekGf75RmJXMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/reseaux/[id]/index.vue?macro=true";
import { default as addvANSTfvDdgMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/reseaux/add.vue?macro=true";
import { default as indexUMKqupmiG3Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/reseaux/index.vue?macro=true";
import { default as editwautbBK8zsMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/ressources/[id]/edit.vue?macro=true";
import { default as addTAl5R9rPjMMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/ressources/add.vue?macro=true";
import { default as indexWouwAXEc8pMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/ressources/index.vue?macro=true";
import { default as edit6tA9oXfjP8Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/territoires/[id]/edit.vue?macro=true";
import { default as index6RhyLiriCtMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/territoires/[id]/index.vue?macro=true";
import { default as addhjV3VDeLcyMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/territoires/add.vue?macro=true";
import { default as indexmD2TN4BuFYMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/territoires/index.vue?macro=true";
import { default as edit9oAzzzd1J3Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/testimonials/[id]/edit.vue?macro=true";
import { default as indexIevprQRbPlMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/testimonials/index.vue?macro=true";
import { default as indexNbj47B2AeyMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/index.vue?macro=true";
import { default as benevoles_45informationsbkxlT6fKKrMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/benevoles-informations.vue?macro=true";
import { default as benevoleswRYhWips0dMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/benevoles.vue?macro=true";
import { default as datespyTcaV0dhmMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/dates.vue?macro=true";
import { default as indexPqNoWvKIE0Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/index.vue?macro=true";
import { default as informationskmxgrAolQCMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/informations.vue?macro=true";
import { default as lieuxSRNVKG24jcMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/lieux.vue?macro=true";
import { default as responsablesy0K5irl3GrMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/responsables.vue?macro=true";
import { default as titleN4WR8ZniQgMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/title.vue?macro=true";
import { default as trouver_45des_45benevolesr01TJAJegyMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/trouver-des-benevoles.vue?macro=true";
import { default as visuelSyioEY90C9Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/visuel.vue?macro=true";
import { default as indexhexn5qz2VDMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/index.vue?macro=true";
import { default as notesVokeZSZ6GHMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/notes.vue?macro=true";
import { default as editaLEaws7dZnMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/organisations/[id]/edit.vue?macro=true";
import { default as indexwZf5VO7dh7Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/organisations/[id]/index.vue?macro=true";
import { default as add8C4VEWFlcyMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/organisations/[id]/missions/add.vue?macro=true";
import { default as indexZFHmQbLJhDMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/organisations/index.vue?macro=true";
import { default as invitationsPlOsv7NUrXMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/other/invitations.vue?macro=true";
import { default as logs3D6kcFypEqMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/other/logs.vue?macro=true";
import { default as index2sfFsCEJI9Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/participations/index.vue?macro=true";
import { default as ressourcesI7iABIvQGlMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/ressources.vue?macro=true";
import { default as emailsWbVTUrV2knMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/settings/emails.vue?macro=true";
import { default as generalRX00kpepV4Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/settings/general.vue?macro=true";
import { default as messagesc9CSnJGjDHMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/settings/messages.vue?macro=true";
import { default as editFmvlTNUioxMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/settings/rules/[id]/edit.vue?macro=true";
import { default as pending_45itemsO4415wrY0QMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/settings/rules/[id]/pending-items.vue?macro=true";
import { default as addYDKyR9hZf0Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/settings/rules/add.vue?macro=true";
import { default as indexywj46WeV36Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/settings/rules/index.vue?macro=true";
import { default as trafic_45entrantmr29ydfejAMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/api-engagement/trafic-entrant.vue?macro=true";
import { default as trafic_45sortantntaP3MgabXMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/api-engagement/trafic-sortant.vue?macro=true";
import { default as conversionsc3MSqZKyBLMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/conversions.vue?macro=true";
import { default as missions_45a_45validerRKPd9dTZDgMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/departements/missions-a-valider.vue?macro=true";
import { default as missions_45perimees1ppZCmEnAcMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/departements/missions-perimees.vue?macro=true";
import { default as organisations_45a_45valider8h0wpEfN4vMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/departements/organisations-a-valider.vue?macro=true";
import { default as indexiMpHKLhVdIMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/index.vue?macro=true";
import { default as indicateurs_45clesbgzRnp3szsMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/indicateurs-cles.vue?macro=true";
import { default as missionsdN3HWfDamfMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/missions.vue?macro=true";
import { default as indexgXcZ71VbOPMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/organisations/index.vue?macro=true";
import { default as missions_45perimeesLIwlxJ933fMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/organisations/missions-perimees.vue?macro=true";
import { default as participations_45a_45validerjbD6JaLKgAMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/organisations/participations-a-valider.vue?macro=true";
import { default as participations_45refusees_45annuleesBqrpZM4SLpMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/organisations/participations-refusees-annulees.vue?macro=true";
import { default as participations8VltpGDzTSMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/participations.vue?macro=true";
import { default as places2fMdxBHmGTMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/places.vue?macro=true";
import { default as utilisateursO78Fbr2ThlMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/utilisateurs.vue?macro=true";
import { default as edit5lj2TaCBqjMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/taxonomies/[slug]/[id]/edit.vue?macro=true";
import { default as addrz4NMWG50ZMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/taxonomies/[slug]/add.vue?macro=true";
import { default as index7MWIz8gu0JMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/taxonomies/[slug]/index.vue?macro=true";
import { default as temoignagesHchI3lJYV9Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/temoignages.vue?macro=true";
import { default as edithu1Ld7RERaMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/utilisateurs/[id]/edit.vue?macro=true";
import { default as indexmuh8tvA76AMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/utilisateurs/[id]/index.vue?macro=true";
import { default as index6qEiWVBMXYMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/utilisateurs/index.vue?macro=true";
import { default as benevolesAHm8royW2fMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/charte-bon-fonctionnement/benevoles.vue?macro=true";
import { default as index0mHqWgPgbgMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/charte-bon-fonctionnement/index.vue?macro=true";
import { default as responsablesc0tS7EP7E9Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/charte-bon-fonctionnement/responsables.vue?macro=true";
import { default as charte_45reserve_45civiquenKiQcWLaREMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/charte-reserve-civique.vue?macro=true";
import { default as conditions_45generales_45d_45utilisationyf1axQeR7PMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/conditions-generales-d-utilisation.vue?macro=true";
import { default as indexaMUaB4ucLlMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/dashboard/index.vue?macro=true";
import { default as _91slug_93pz51r42MmaMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/departements/[slug].vue?macro=true";
import { default as _91slug_93NkOilBsdMmMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/domaines-action/[slug].vue?macro=true";
import { default as en_45ce_45momentcscYtqk6YfMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/en-ce-moment.vue?macro=true";
import { default as indexNNnKdWCArrMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/index.vue?macro=true";
import { default as indexZhKTlLHKtjMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/benevole/index.vue?macro=true";
import { default as competencesK31NTT87tRMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/benevole/step/competences.vue?macro=true";
import { default as disponibilitesxiVy1NaimoMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/benevole/step/disponibilites.vue?macro=true";
import { default as preferences0WqBMSOKCqMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/benevole/step/preferences.vue?macro=true";
import { default as profileLfPR3qbBsdMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/benevole/step/profile.vue?macro=true";
import { default as indexwtAqk61r0FMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/index.vue?macro=true";
import { default as indexPBh8BCdaQzMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/index.vue?macro=true";
import { default as charte_45bon_45fonctionnementYonEK1z1eKMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/charte-bon-fonctionnement.vue?macro=true";
import { default as collectivite_45confirmationKm47TmCz4OMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/collectivite-confirmation.vue?macro=true";
import { default as collectiviteYRbNKwIuVCMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/collectivite.vue?macro=true";
import { default as organisation_45confirmation4t29dS1o9WMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/organisation-confirmation.vue?macro=true";
import { default as organisation_45details0XrcvBfRhqMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/organisation-details.vue?macro=true";
import { default as organisation_45imagesYkPxwyxqzlMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/organisation-images.vue?macro=true";
import { default as organisation7dXCPDuaT4Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/organisation.vue?macro=true";
import { default as profilewtgyP1VI6AMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/profile.vue?macro=true";
import { default as _91hash_932cfQjSIxMrMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/invitations/[hash].vue?macro=true";
import { default as loginKzNrLzkVlSMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/login.vue?macro=true";
import { default as admin5asp32V9ERMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/maintenance/admin.vue?macro=true";
import { default as indexmq8jfvSLsqMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/maintenance/index.vue?macro=true";
import { default as mentions_45legalesVUWhpF785rMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/mentions-legales.vue?macro=true";
import { default as _91id_93QbdpPzUjx3Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/messages/[id].vue?macro=true";
import { default as indexqwPy0VqbcjMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/messages/index.vue?macro=true";
import { default as addQTCGiaWZhvMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/messages/modeles/add.vue?macro=true";
import { default as indexoTIOi75mkiMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/messages/modeles/index.vue?macro=true";
import { default as _91slug_93eFmFRXCkjJMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/missions-benevolat/[id]/[slug].vue?macro=true";
import { default as indexi70GVjzYtBMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/missions-benevolat/[id]/index.vue?macro=true";
import { default as indexAwRYzzzE2QMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/missions-benevolat/index.vue?macro=true";
import { default as notificationsCVk8AdEnGFMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/notifications.vue?macro=true";
import { default as _91slug_93aanteXLcKYMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/organisations/[slug].vue?macro=true";
import { default as indexLZwCtUBLnCMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/organisations/index.vue?macro=true";
import { default as _91token_93k1c6q0GKp2Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/password-reset/[token].vue?macro=true";
import { default as indexr5SxcIhvpBMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/password-reset/index.vue?macro=true";
import { default as plan_45du_45siteRRHoCyxn1wMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/plan-du-site.vue?macro=true";
import { default as politique_45de_45confidentialiteVcSSPNfyQfMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/politique-de-confidentialite.vue?macro=true";
import { default as charte_45bon_45fonctionnementXL801IsEpcMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/profile/charte-bon-fonctionnement.vue?macro=true";
import { default as editjZcdkYh9UhMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/profile/edit.vue?macro=true";
import { default as index3CwkqhXJoIMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/profile/index.vue?macro=true";
import { default as missionsXtreOOklOGMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/profile/missions.vue?macro=true";
import { default as notificationsJEHuFtM9FUMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/profile/notifications.vue?macro=true";
import { default as preferencesMdqZWtCD0uMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/profile/preferences.vue?macro=true";
import { default as settingsCGiKejFRvbMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/profile/settings.vue?macro=true";
import { default as decembre_45ensembleFgfHJEhUWgMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/quiz/decembre-ensemble.vue?macro=true";
import { default as generique7JegWfsTX5Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/quiz/generique.vue?macro=true";
import { default as mgenzhcr0aFamAMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/quiz/mgen.vue?macro=true";
import { default as printemps_45pour_45la_45planetezfVAs8NIv3Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/quiz/printemps-pour-la-planete.vue?macro=true";
import { default as septembre_45pour_45apprendregWccmCmFkNMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/quiz/septembre-pour-apprendre.vue?macro=true";
import { default as _91slug_935RJAv6tFbfMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/reseaux/[slug].vue?macro=true";
import { default as index2AXTJ3mQrDMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/stats/index.vue?macro=true";
import { default as missionshUjTzS4PGzMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/stats/missions.vue?macro=true";
import { default as organisationsl7n5HSDIwYMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/stats/organisations.vue?macro=true";
import { default as participationsOsPRxBDqMeMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/stats/participations.vue?macro=true";
import { default as places4ifnz2LXTcMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/stats/places.vue?macro=true";
import { default as utilisateursGDS33KQ9LSMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/stats/utilisateurs.vue?macro=true";
import { default as search_45archived_45usersb31kn2qm6gMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/actions/search-archived-users.vue?macro=true";
import { default as transfert_45organisationXlz9MuGEaeMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/actions/transfert-organisation.vue?macro=true";
import { default as user_45reset_45context_45rolemiuomGf2ZqMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/actions/user-reset-context-role.vue?macro=true";
import { default as user_45reset_45password_45linkvR1LCVxZeDMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/actions/user-reset-password-link.vue?macro=true";
import { default as doublons_45organisationsJDHiWzKAdsMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/contents/doublons-organisations.vue?macro=true";
import { default as doublons_45territoiresz3KInqqTC5Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/contents/doublons-territoires.vue?macro=true";
import { default as indexV2CiN0GHoZMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/index.vue?macro=true";
import { default as activity_45logsu899NmkoP8Meta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/referents/activity-logs.vue?macro=true";
import { default as waiting_45actionsNKVIt8GwSyMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/referents/waiting-actions.vue?macro=true";
import { default as missions_45outdated1nLaw3Sc3qMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/responsables/missions-outdated.vue?macro=true";
import { default as participations_45to_45be_45treatedyrBaGqepYXMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/responsables/participations-to-be-treated.vue?macro=true";
import { default as _91token_930Bwuuep5jKMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/temoignages/[token].vue?macro=true";
import { default as index2wU1xXVglWMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/territoires/index.vue?macro=true";
import { default as _91slug_93A3QgdvDCcWMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/villes/[slug].vue?macro=true";
import { default as component_45stubDx8XKpPdWHMeta } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubDx8XKpPdWH } from "/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "accessibilite",
    path: "/accessibilite",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/accessibilite.vue").then(m => m.default || m)
  },
  {
    name: "activites-slug",
    path: "/activites/:slug()",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/activites/[slug].vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-id-edit",
    path: "/admin/contenus/activites/:id()/edit",
    meta: editFJkWZVpObkMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/activites/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-id",
    path: "/admin/contenus/activites/:id()",
    meta: index0k2wjbPWqBMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/activites/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-add",
    path: "/admin/contenus/activites/add",
    meta: addembwm64q5UMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/activites/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites",
    path: "/admin/contenus/activites",
    meta: indexAc1T6lfOJYMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/activites/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines-id-edit",
    path: "/admin/contenus/domaines/:id()/edit",
    meta: edit4Hv4HuGJTiMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/domaines/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines-add",
    path: "/admin/contenus/domaines/add",
    meta: add95M3VeXyTzMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/domaines/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines",
    path: "/admin/contenus/domaines",
    meta: indexV3VElyz8ebMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/domaines/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission-id-edit",
    path: "/admin/contenus/modeles-mission/:id()/edit",
    meta: editZBSVfTkqrjMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/modeles-mission/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission-add",
    path: "/admin/contenus/modeles-mission/add",
    meta: add4jM1ifz3cYMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/modeles-mission/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission",
    path: "/admin/contenus/modeles-mission",
    meta: indexQpnPnIAWGEMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/modeles-mission/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-id-edit",
    path: "/admin/contenus/reseaux/:id()/edit",
    meta: editFgpejLGOC1Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/reseaux/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-id",
    path: "/admin/contenus/reseaux/:id()",
    meta: indexekGf75RmJXMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/reseaux/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-add",
    path: "/admin/contenus/reseaux/add",
    meta: addvANSTfvDdgMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/reseaux/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux",
    path: "/admin/contenus/reseaux",
    meta: indexUMKqupmiG3Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/reseaux/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources-id-edit",
    path: "/admin/contenus/ressources/:id()/edit",
    meta: editwautbBK8zsMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/ressources/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources-add",
    path: "/admin/contenus/ressources/add",
    meta: addTAl5R9rPjMMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/ressources/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources",
    path: "/admin/contenus/ressources",
    meta: indexWouwAXEc8pMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/ressources/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-id-edit",
    path: "/admin/contenus/territoires/:id()/edit",
    meta: edit6tA9oXfjP8Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/territoires/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-id",
    path: "/admin/contenus/territoires/:id()",
    meta: index6RhyLiriCtMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/territoires/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-add",
    path: "/admin/contenus/territoires/add",
    meta: addhjV3VDeLcyMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/territoires/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires",
    path: "/admin/contenus/territoires",
    meta: indexmD2TN4BuFYMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-testimonials-id-edit",
    path: "/admin/contenus/testimonials/:id()/edit",
    meta: edit9oAzzzd1J3Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/testimonials/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-testimonials",
    path: "/admin/contenus/testimonials",
    meta: indexIevprQRbPlMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/contenus/testimonials/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexNbj47B2AeyMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-benevoles-informations",
    path: "/admin/missions/:id()/benevoles-informations",
    meta: benevoles_45informationsbkxlT6fKKrMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/benevoles-informations.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-benevoles",
    path: "/admin/missions/:id()/benevoles",
    meta: benevoleswRYhWips0dMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/benevoles.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-dates",
    path: "/admin/missions/:id()/dates",
    meta: datespyTcaV0dhmMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/dates.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id",
    path: "/admin/missions/:id()",
    meta: indexPqNoWvKIE0Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-informations",
    path: "/admin/missions/:id()/informations",
    meta: informationskmxgrAolQCMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/informations.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-lieux",
    path: "/admin/missions/:id()/lieux",
    meta: lieuxSRNVKG24jcMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/lieux.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-responsables",
    path: "/admin/missions/:id()/responsables",
    meta: responsablesy0K5irl3GrMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/responsables.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-title",
    path: "/admin/missions/:id()/title",
    meta: titleN4WR8ZniQgMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/title.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-trouver-des-benevoles",
    path: "/admin/missions/:id()/trouver-des-benevoles",
    meta: trouver_45des_45benevolesr01TJAJegyMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/trouver-des-benevoles.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-visuel",
    path: "/admin/missions/:id()/visuel",
    meta: visuelSyioEY90C9Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/[id]/visuel.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions",
    path: "/admin/missions",
    meta: indexhexn5qz2VDMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/missions/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-notes",
    path: "/admin/notes",
    meta: notesVokeZSZ6GHMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/notes.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id-edit",
    path: "/admin/organisations/:id()/edit",
    meta: editaLEaws7dZnMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/organisations/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id",
    path: "/admin/organisations/:id()",
    meta: indexwZf5VO7dh7Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/organisations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id-missions-add",
    path: "/admin/organisations/:id()/missions/add",
    meta: add8C4VEWFlcyMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/organisations/[id]/missions/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations",
    path: "/admin/organisations",
    meta: indexZFHmQbLJhDMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-other-invitations",
    path: "/admin/other/invitations",
    meta: invitationsPlOsv7NUrXMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/other/invitations.vue").then(m => m.default || m)
  },
  {
    name: "admin-other-logs",
    path: "/admin/other/logs",
    meta: logs3D6kcFypEqMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/other/logs.vue").then(m => m.default || m)
  },
  {
    name: "admin-participations",
    path: "/admin/participations",
    meta: index2sfFsCEJI9Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/participations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-ressources",
    path: "/admin/ressources",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/ressources.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-emails",
    path: "/admin/settings/emails",
    meta: emailsWbVTUrV2knMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/settings/emails.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-general",
    path: "/admin/settings/general",
    meta: generalRX00kpepV4Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/settings/general.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-messages",
    path: "/admin/settings/messages",
    meta: messagesc9CSnJGjDHMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/settings/messages.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-id-edit",
    path: "/admin/settings/rules/:id()/edit",
    meta: editFmvlTNUioxMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/settings/rules/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-id-pending-items",
    path: "/admin/settings/rules/:id()/pending-items",
    meta: pending_45itemsO4415wrY0QMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/settings/rules/[id]/pending-items.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-add",
    path: "/admin/settings/rules/add",
    meta: addYDKyR9hZf0Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/settings/rules/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules",
    path: "/admin/settings/rules",
    meta: indexywj46WeV36Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/settings/rules/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-api-engagement-trafic-entrant",
    path: "/admin/statistics/api-engagement/trafic-entrant",
    meta: trafic_45entrantmr29ydfejAMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/api-engagement/trafic-entrant.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-api-engagement-trafic-sortant",
    path: "/admin/statistics/api-engagement/trafic-sortant",
    meta: trafic_45sortantntaP3MgabXMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/api-engagement/trafic-sortant.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-conversions",
    path: "/admin/statistics/conversions",
    meta: conversionsc3MSqZKyBLMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/conversions.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-missions-a-valider",
    path: "/admin/statistics/departements/missions-a-valider",
    meta: missions_45a_45validerRKPd9dTZDgMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/departements/missions-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-missions-perimees",
    path: "/admin/statistics/departements/missions-perimees",
    meta: missions_45perimees1ppZCmEnAcMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/departements/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-organisations-a-valider",
    path: "/admin/statistics/departements/organisations-a-valider",
    meta: organisations_45a_45valider8h0wpEfN4vMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/departements/organisations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics",
    path: "/admin/statistics",
    meta: indexiMpHKLhVdIMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-indicateurs-cles",
    path: "/admin/statistics/indicateurs-cles",
    meta: indicateurs_45clesbgzRnp3szsMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/indicateurs-cles.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-missions",
    path: "/admin/statistics/missions",
    meta: missionsdN3HWfDamfMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/missions.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations",
    path: "/admin/statistics/organisations",
    meta: indexgXcZ71VbOPMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-missions-perimees",
    path: "/admin/statistics/organisations/missions-perimees",
    meta: missions_45perimeesLIwlxJ933fMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/organisations/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-participations-a-valider",
    path: "/admin/statistics/organisations/participations-a-valider",
    meta: participations_45a_45validerjbD6JaLKgAMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/organisations/participations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-participations-refusees-annulees",
    path: "/admin/statistics/organisations/participations-refusees-annulees",
    meta: participations_45refusees_45annuleesBqrpZM4SLpMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/organisations/participations-refusees-annulees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-participations",
    path: "/admin/statistics/participations",
    meta: participations8VltpGDzTSMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/participations.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-places",
    path: "/admin/statistics/places",
    meta: places2fMdxBHmGTMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/places.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-utilisateurs",
    path: "/admin/statistics/utilisateurs",
    meta: utilisateursO78Fbr2ThlMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/statistics/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug-id-edit",
    path: "/admin/taxonomies/:slug()/:id()/edit",
    meta: edit5lj2TaCBqjMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/taxonomies/[slug]/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug-add",
    path: "/admin/taxonomies/:slug()/add",
    meta: addrz4NMWG50ZMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/taxonomies/[slug]/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug",
    path: "/admin/taxonomies/:slug()",
    meta: index7MWIz8gu0JMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/taxonomies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-temoignages",
    path: "/admin/temoignages",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/temoignages.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs-id-edit",
    path: "/admin/utilisateurs/:id()/edit",
    meta: edithu1Ld7RERaMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/utilisateurs/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs-id",
    path: "/admin/utilisateurs/:id()",
    meta: indexmuh8tvA76AMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/utilisateurs/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs",
    path: "/admin/utilisateurs",
    meta: index6qEiWVBMXYMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/admin/utilisateurs/index.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement-benevoles",
    path: "/charte-bon-fonctionnement/benevoles",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/charte-bon-fonctionnement/benevoles.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement",
    path: "/charte-bon-fonctionnement",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/charte-bon-fonctionnement/index.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement-responsables",
    path: "/charte-bon-fonctionnement/responsables",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/charte-bon-fonctionnement/responsables.vue").then(m => m.default || m)
  },
  {
    name: "charte-reserve-civique",
    path: "/charte-reserve-civique",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/charte-reserve-civique.vue").then(m => m.default || m)
  },
  {
    name: "conditions-generales-d-utilisation",
    path: "/conditions-generales-d-utilisation",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/conditions-generales-d-utilisation.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexaMUaB4ucLlMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "departements-slug",
    path: "/departements/:slug()",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/departements/[slug].vue").then(m => m.default || m)
  },
  {
    name: "domaines-action-slug",
    path: "/domaines-action/:slug()",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/domaines-action/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-ce-moment",
    path: "/en-ce-moment",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/en-ce-moment.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole",
    path: "/inscription/benevole",
    meta: indexZhKTlLHKtjMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/benevole/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-competences",
    path: "/inscription/benevole/step/competences",
    meta: competencesK31NTT87tRMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/benevole/step/competences.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-disponibilites",
    path: "/inscription/benevole/step/disponibilites",
    meta: disponibilitesxiVy1NaimoMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/benevole/step/disponibilites.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-preferences",
    path: "/inscription/benevole/step/preferences",
    meta: preferences0WqBMSOKCqMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/benevole/step/preferences.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-profile",
    path: "/inscription/benevole/step/profile",
    meta: profileLfPR3qbBsdMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/benevole/step/profile.vue").then(m => m.default || m)
  },
  {
    name: "inscription",
    path: "/inscription",
    meta: indexwtAqk61r0FMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable",
    path: "/inscription/responsable",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-charte-bon-fonctionnement",
    path: "/inscription/responsable/step/charte-bon-fonctionnement",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-collectivite-confirmation",
    path: "/inscription/responsable/step/collectivite-confirmation",
    meta: collectivite_45confirmationKm47TmCz4OMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/collectivite-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-collectivite",
    path: "/inscription/responsable/step/collectivite",
    meta: collectiviteYRbNKwIuVCMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/collectivite.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-confirmation",
    path: "/inscription/responsable/step/organisation-confirmation",
    meta: organisation_45confirmation4t29dS1o9WMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/organisation-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-details",
    path: "/inscription/responsable/step/organisation-details",
    meta: organisation_45details0XrcvBfRhqMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/organisation-details.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-images",
    path: "/inscription/responsable/step/organisation-images",
    meta: organisation_45imagesYkPxwyxqzlMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/organisation-images.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation",
    path: "/inscription/responsable/step/organisation",
    meta: organisation7dXCPDuaT4Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/organisation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-profile",
    path: "/inscription/responsable/step/profile",
    meta: profilewtgyP1VI6AMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/inscription/responsable/step/profile.vue").then(m => m.default || m)
  },
  {
    name: "invitations-hash",
    path: "/invitations/:hash()",
    meta: _91hash_932cfQjSIxMrMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/invitations/[hash].vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginKzNrLzkVlSMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "maintenance-admin",
    path: "/maintenance/admin",
    meta: admin5asp32V9ERMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/maintenance/admin.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: indexmq8jfvSLsqMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "messages-id",
    path: "/messages/:id()",
    meta: _91id_93QbdpPzUjx3Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "messages",
    path: "/messages",
    meta: indexqwPy0VqbcjMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "messages-modeles-add",
    path: "/messages/modeles/add",
    meta: addQTCGiaWZhvMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/messages/modeles/add.vue").then(m => m.default || m)
  },
  {
    name: "messages-modeles",
    path: "/messages/modeles",
    meta: indexoTIOi75mkiMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/messages/modeles/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat-id-slug",
    path: "/missions-benevolat/:id()/:slug()",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/missions-benevolat/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat-id",
    path: "/missions-benevolat/:id()",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/missions-benevolat/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat",
    path: "/missions-benevolat",
    meta: indexAwRYzzzE2QMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/missions-benevolat/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notificationsCVk8AdEnGFMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "organisations-slug",
    path: "/organisations/:slug()",
    meta: _91slug_93aanteXLcKYMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/organisations/[slug].vue").then(m => m.default || m)
  },
  {
    name: "organisations",
    path: "/organisations",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password-reset/:token()",
    meta: _91token_93k1c6q0GKp2Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: indexr5SxcIhvpBMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-du-site",
    path: "/plan-du-site",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/plan-du-site.vue").then(m => m.default || m)
  },
  {
    name: "politique-de-confidentialite",
    path: "/politique-de-confidentialite",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/politique-de-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: "profile-charte-bon-fonctionnement",
    path: "/profile/charte-bon-fonctionnement",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/profile/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    meta: editjZcdkYh9UhMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: index3CwkqhXJoIMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-missions",
    path: "/profile/missions",
    meta: missionsXtreOOklOGMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/profile/missions.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: notificationsJEHuFtM9FUMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "profile-preferences",
    path: "/profile/preferences",
    meta: preferencesMdqZWtCD0uMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/profile/preferences.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: settingsCGiKejFRvbMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "quiz-decembre-ensemble",
    path: "/quiz/decembre-ensemble",
    meta: decembre_45ensembleFgfHJEhUWgMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/quiz/decembre-ensemble.vue").then(m => m.default || m)
  },
  {
    name: "quiz-generique",
    path: "/quiz/generique",
    meta: generique7JegWfsTX5Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/quiz/generique.vue").then(m => m.default || m)
  },
  {
    name: "quiz-mgen",
    path: "/quiz/mgen",
    meta: mgenzhcr0aFamAMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/quiz/mgen.vue").then(m => m.default || m)
  },
  {
    name: "quiz-printemps-pour-la-planete",
    path: "/quiz/printemps-pour-la-planete",
    meta: printemps_45pour_45la_45planetezfVAs8NIv3Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/quiz/printemps-pour-la-planete.vue").then(m => m.default || m)
  },
  {
    name: "quiz-septembre-pour-apprendre",
    path: "/quiz/septembre-pour-apprendre",
    meta: septembre_45pour_45apprendregWccmCmFkNMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/quiz/septembre-pour-apprendre.vue").then(m => m.default || m)
  },
  {
    name: "reseaux-slug",
    path: "/reseaux/:slug()",
    meta: _91slug_935RJAv6tFbfMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/reseaux/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stats",
    path: "/stats",
    meta: index2AXTJ3mQrDMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/stats/index.vue").then(m => m.default || m)
  },
  {
    name: "stats-missions",
    path: "/stats/missions",
    meta: missionshUjTzS4PGzMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/stats/missions.vue").then(m => m.default || m)
  },
  {
    name: "stats-organisations",
    path: "/stats/organisations",
    meta: organisationsl7n5HSDIwYMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/stats/organisations.vue").then(m => m.default || m)
  },
  {
    name: "stats-participations",
    path: "/stats/participations",
    meta: participationsOsPRxBDqMeMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/stats/participations.vue").then(m => m.default || m)
  },
  {
    name: "stats-places",
    path: "/stats/places",
    meta: places4ifnz2LXTcMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/stats/places.vue").then(m => m.default || m)
  },
  {
    name: "stats-utilisateurs",
    path: "/stats/utilisateurs",
    meta: utilisateursGDS33KQ9LSMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/stats/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-search-archived-users",
    path: "/support/actions/search-archived-users",
    meta: search_45archived_45usersb31kn2qm6gMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/actions/search-archived-users.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-transfert-organisation",
    path: "/support/actions/transfert-organisation",
    meta: transfert_45organisationXlz9MuGEaeMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/actions/transfert-organisation.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-user-reset-context-role",
    path: "/support/actions/user-reset-context-role",
    meta: user_45reset_45context_45rolemiuomGf2ZqMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/actions/user-reset-context-role.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-user-reset-password-link",
    path: "/support/actions/user-reset-password-link",
    meta: user_45reset_45password_45linkvR1LCVxZeDMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/actions/user-reset-password-link.vue").then(m => m.default || m)
  },
  {
    name: "support-contents-doublons-organisations",
    path: "/support/contents/doublons-organisations",
    meta: doublons_45organisationsJDHiWzKAdsMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/contents/doublons-organisations.vue").then(m => m.default || m)
  },
  {
    name: "support-contents-doublons-territoires",
    path: "/support/contents/doublons-territoires",
    meta: doublons_45territoiresz3KInqqTC5Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/contents/doublons-territoires.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    meta: indexV2CiN0GHoZMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "support-referents-activity-logs",
    path: "/support/referents/activity-logs",
    meta: activity_45logsu899NmkoP8Meta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/referents/activity-logs.vue").then(m => m.default || m)
  },
  {
    name: "support-referents-waiting-actions",
    path: "/support/referents/waiting-actions",
    meta: waiting_45actionsNKVIt8GwSyMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/referents/waiting-actions.vue").then(m => m.default || m)
  },
  {
    name: "support-responsables-missions-outdated",
    path: "/support/responsables/missions-outdated",
    meta: missions_45outdated1nLaw3Sc3qMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/responsables/missions-outdated.vue").then(m => m.default || m)
  },
  {
    name: "support-responsables-participations-to-be-treated",
    path: "/support/responsables/participations-to-be-treated",
    meta: participations_45to_45be_45treatedyrBaGqepYXMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/support/responsables/participations-to-be-treated.vue").then(m => m.default || m)
  },
  {
    name: "temoignages-token",
    path: "/temoignages/:token()",
    meta: _91token_930Bwuuep5jKMeta || {},
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/temoignages/[token].vue").then(m => m.default || m)
  },
  {
    name: "territoires",
    path: "/territoires",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: "villes-slug",
    path: "/villes/:slug()",
    component: () => import("/build/b3bc8cbf-72a0-40ad-91c9-3382eb38e0d6/pages/villes/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/register/volontaire/:pathMatch(.*)",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/register/responsable/:pathMatch(.*)",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/user/settings",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/user/:pathMatch(.*)",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/dashboard/structure/:pathMatch(.*)",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/dashboard/mission/:pathMatch(.*)",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/dashboard/participation/:pathMatch(.*)",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/dashboard/profile/:pathMatch(.*)",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/dashboard/reseaux/:pathMatch(.*)",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/inscription/organisation",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/statistiques",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/activites/accompagnement-aux-activites-sportives",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/missions-sitemap.xml",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/organisations-sitemap.xml",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/villes-sitemap.xml",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/departements-sitemap.xml",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/reseaux-sitemap.xml",
    component: component_45stubDx8XKpPdWH
  },
  {
    name: component_45stubDx8XKpPdWHMeta?.name,
    path: "/activites-sitemap.xml",
    component: component_45stubDx8XKpPdWH
  }
]